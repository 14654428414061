<template>
        <section>
  <r-e-dialog title="抄表记录" :visible.sync="dialogVisible" top="5vh" width="900px" :show-footer="false">
    <r-e-table  ref="communityTableRef" :columns="openmanMeterRecord" :dataRequest="getFamilyList" :query="formSearch" :height="300" :row-style="rowElectStyle">
      <template slot="empty">
        <el-empty/>
      </template>
      <el-table-column slot="toolbar" label="操作" >
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn"  @click="handlePopto(row)">图片</span>
                    </div>
                </template>
            </el-table-column>
    </r-e-table>
  </r-e-dialog>
  <r-e-dialog title="抄表记录图片" :visible.sync="dialogPoptoVisible" show-footer top="10vh" width="600px"
                    @click-submit="PoptoClickSubmit" @click-cancel="PoptoClickCancel" @close="PoptoClickCancel">
                        <div class="flex" style="height: 300px;overflow:scroll; flex-wrap: wrap;">
                          <!-- <div v-for="(item,index) in data.thumbnail" :key="index"> -->
                          <div class="flex justify-center" style="margin:10px; background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px" v-for="item in dialogPoptoPhotos" :key="item">
                            <el-image :src="item" class="flex align-center"
                              :preview-src-list="dialogPoptoPhotos" >
                              <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                              <div slot="placeholder">加载中...</div>
                           </el-image>
                          </div>
                          <!-- </div> -->
                        </div>
        </r-e-dialog>
      </section>
</template>

<script>
import {openmanMeterRecord} from "@/views/property-management/door-management/data";
import {openLocksLog, getmanMeterRecordapi} from "@/api/door-management"
import {createFullImageUrl} from "@/components/Upload";
export default {
  name: "dialog-show-unlock-record",
  data() {
    return {
      dialogPoptoPhotos:[],
      dialogVisible: false,
      dataList: [],
      openmanMeterRecord,
      type: '',
      uuid: '',
      dateArr: [],
      formSearch: {},
      dialogPoptoVisible:false, //照片弹窗
    }
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  methods: {
    openDialog(data) {
      this.uuid = data;
      this.dialogVisible = true;
    },
    getFamilyList() {
      let {uuid} = this;
      // params.type = this.type;
      return getmanMeterRecordapi({apartmentUuid:uuid});
    },
    handleSearch() {
      this.$refs["communityTableRef"].pNumber = 1;
      this.$refs["communityTableRef"].getTableData();
    },
            // 照片提交
            PoptoClickSubmit(){
            this.dialogPoptoVisible = false;
        },
        // 照片取消
        PoptoClickCancel(){
            this.handleSearch();
            // this.dialogVisible = false;
            this.dialogPoptoVisible = false;
        },
                // 2024/04/15 张晓瑜新增照片点击事件
                handlePopto(data) {
                  console.log('data',data);
            let { attachment } = data;

            // 将 photos 按分号分隔成数组，并过滤掉空字符串
            let photoUrls = attachment ? attachment.split(",").filter(item => item !== "") : [];
            let processedPhotoUrls = photoUrls.map(createFullImageUrl); // 对每个图片链接应用 createFullImageUrl 函数

            // 将 photoUrls 存储到数组中
            this.dialogPoptoVisible = true;
            this.dialogPoptoPhotos = processedPhotoUrls; // 将处理后的结果存储到 dialogPoptoPhotos 中
        },
  },
}
</script>

<style scoped>

</style>